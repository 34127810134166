<div class="landingPageComponents">
  <div class="landingPageWelcome">
    <div class="landingPageHeadlineBox">
      <img
        class="landingPageHeadlineImage"
        src="assets/images/AksenovKonstantinBewerbungsfoto.avif"
        alt="Portrait of developer."
      />

      <h1 class="landingPageHeadline">Frontend Developer</h1>
    </div>

    <div class="landingPageHeadlineBox">
      <div class="landingPageHeadlineSolidLine"></div>
      <h3 class="landingPageHeadlineName">Konstantin Aksenov</h3>
    </div>
  </div>
</div>

<!-- <app-about></app-about>
<app-skills></app-skills>
<app-portfolio></app-portfolio>
<app-comments></app-comments>
<app-contact></app-contact> -->
